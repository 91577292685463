@import 'src/styles/variables.scss';

.container {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 35px;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img {
  width: 24px;
  height: 24px;
}

.iconsWrapper {
  margin-top: 10px;
  cursor: pointer;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  max-width: 250px;
}
.title {
  font-size: 16px;
  font-weight: 700;
  color: #191920;
  line-height: initial;
  color: $dark100;
}

.name {
  font-size: 13px;
  font-weight: 500;
  color: $dark100;
}

.price {
  font-size: 13px;
  font-weight: 500;
  color: #191920;
  line-height: initial;
  color: $dark100;
}
