.videoModalContent {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    overflow-y: auto;
    height: calc(100vh - 20px);
  }
}

.video {
  @media (max-width: 768px) {
    display: none;
  }
}
