@import 'src/styles/variables.scss';

.popover {
  padding: 5px;
}

.container {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    // height: 350px;
    padding-top: 0px;
  }
}

.buttons {
  flex-direction: column;
  display: flex;
  // gap: 10px;
  @media (max-width: 768px) {
    margin-left: 20px;
    margin-bottom: 20px;
  }
}
.text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #161823 !important;
  margin-left: 2px;
}

.btnsWrapper {
  display: flex;
  gap: 10px;
}

.timeWrapper {
  display: flex;
  gap: 10px;
}

.moneyInfo {
  display: flex;
  gap: 10px;
}

.blackBlock {
  background: #34343c;
  color: white;
  border-radius: 4px;
  padding: 6px 22px;
  font-size: 14px;
}

.showMore {
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  color: #ef3f39;
  border: none;
  cursor: pointer;
}

.desc {
  margin-top: 10px;
  font-weight: 400;
}

.auctionInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #161823;
  font-weight: 700;
}

.auction {
  @media (max-width: 768px) {
    display: none;
  }
}

.mobileAuction {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  padding-left: 20px;
  padding-right: 20px;
}

.auctionLink {
  background-color: #f5f5f7;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 10px;

  // @media (max-width: 768px) {

  // }
}

.auctionLinkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auctionTitle {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: #161823;

  @media (max-width: 768px) {
    color: white;
    font-size: 15px;
  }
}

.btn {
  cursor: pointer !important;
  color: white;
  background-color: #f0544f;
  border: none;
  font-weight: 600;
  height: 36px;
  min-width: 148px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;

  &:hover {
    background-color: #c04440;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 95px;
  }
}

.linkMobile {
  color: #161823;
}

.container_item {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 20px;
  position: relative;
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    display: none;
  }
}

.members {
  cursor: pointer;
  border: 1px solid #c9c9d5;
  border-radius: 4px;
  height: 31px;
  width: 26px;

  :hover {
    opacity: 0.7;
  }
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  width: 24px;
  height: 24px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  display: flex;
  gap: 10px;
  justify-content: center;
  // margin-bottom: 32px;
  @media (max-width: 768px) {
    display: none;
  }
}

// .info {
//   max-width: 250px;

//   @media (max-width: 768px) {
//     max-width: 100%;
//   }
// }
.dataTop {
  @media (max-width: 768px) {
  }
}

.title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #161823;

  @media (max-width: 768px) {
    color: white;
    margin-top: 250px;
    margin-left: 20px;
    font-size: 18px;
  }
}

.iconsWrapper {
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 295px;
    margin-left: 20px;
  }
}

.bio {
  font-size: 18px;
  font-weight: 500;
  color: #191920;
  line-height: initial;
  color: $dark100;
  @media (max-width: 768px) {
    color: white;
    display: none;
  }
}

.name {
  font-size: 15px;
  font-weight: 500;
  color: $dark100;
}

.info {
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  color: #161823;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: rgba(22, 24, 35, 0.7490196078);
    // margin-left: 6px;
  }
}

.categoryList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 300px;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
}

.categories {
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    display: none;
  }
}

.editInput {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #161823;

  @media (max-width: 768px) {
    color: white;
    margin-top: 250px;
    margin-left: 20px;
    font-size: 18px;
  }
}

// .absoluteInput {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: transparent;
//   z-index: 1; // Ensure it overlays the title
//   text-align: center;
//   font-weight: bold;
// }
