.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loaderSpinner {
  width: 48px;
  height: 48px;
  border: 5px solid transparent;
  border-top: 5px solid #e52e2e;
  border-radius: 50%;
  animation: spinArc 0.8s linear infinite;
}

@keyframes spinArc {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.landscapeVideo {
  object-fit: contain;
  background-color: black;
  width: 100%;
  height: 100%;
}

.feedPage {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;

  @media (max-width: 550px) {
    height: calc(100% - 60px);
    margin-top: 60px;
    overflow: visible;
    display: block;
  }
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 550px) {
    display: block;
  }
}

.tribeName {
  margin-left: 9px;
  font-weight: 700;
}

.videoWrapper {
  width: 100%;
  height: calc(100vh - 72px);
  // margin: 1000px 0;
  background-color: black;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  @media (max-width: 550px) {
    height: calc(100vh - 40px);
    margin: 20px 0;
  }
}

.videoWrapper::before,
.videoWrapper::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1;
  pointer-events: none;
}

.videoWrapper::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
}

.videoWrapper::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}

.videoWrapperGoogle,
.videoWrapperSafari {
  @media (max-width: 550px) {
    height: 100vh !important;
  }
}

.video {
  width: 371px;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  display: block;
}

.hidden {
  display: none;
}

.loadingMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-size: 16px;
  font-weight: bold;
}

.videoOverlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
}

.navigationButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  @media (max-width: 550px) {
    display: none;
  }
}

.dateOverlay {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.commentOverlay {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.commentIcon {
  margin-right: 5px;
  font-size: 16px;
}

.challengeOverlay {
  position: absolute;
  bottom: 8px;
  width: 100%;
  color: white;
  z-index: 2;
  text-align: left;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
}

.challengeItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.moreItem {
  margin-left: 8px;
}

.challengeImage {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
}

.dunbarIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dunbarIcon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.commentsCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 12px;
}

.topData {
  margin-left: 8px;
}

.circleName {
  font-size: 14px;
  font-weight: 600;
}

.time {
  font-size: 12px;
}

.bottomTitle {
  margin-top: 15px;
  margin-left: 8px;
  font-size: 13px;
  font-weight: 600;
}

.challengeTitle {
  font-size: 12px;
  line-height: 100%;
  margin-left: 9px;
}
