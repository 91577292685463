.feedPage {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;

  @media (max-width: 550px) {
    height: calc(100% - 60px);
    margin-top: 56px;
    overflow: visible;
    display: block;
  }
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 550px) {
    display: block;
  }
}

.tribeName {
  margin-left: 9px;
  font-weight: 700;
}

.videoWrapper {
  width: 360px;
  height: 540px;
  background-color: black; /* Default gray background */
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);

  &.isLoading {
    background-color: #e3e3e3;
  }

  @media (max-width: 550px) {
    width: auto;
    height: calc(100vh - 60px);
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  display: block;
  // height: 400px;

  @media (max-width: 550px) {
    // width: 200px;
    // height: 400px;
  }
}

.hidden {
  display: none;
}

.loadingMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-size: 16px;
  font-weight: bold;
}

.videoOverlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
}

.navigationButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  @media (max-width: 550px) {
    display: none;
  }
}

.dateOverlay {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.commentOverlay {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.commentIcon {
  margin-right: 5px;
  font-size: 16px;
}

.challengeOverlay {
  position: absolute;
  bottom: 15px;
  width: 100%;
  color: white;
  z-index: 2;
  text-align: left;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
}

.challengeItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.moreItem {
  margin-left: 8px;
}

.challengeImage {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
}

.dunbarIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dunbarIcon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
