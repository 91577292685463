/* BulkAddMembersModal.module.scss */
@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 80vh;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  h2 {
    margin: 0;
    font-size: 17px;
    font-weight: 700;
    color: $dark90;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(545px) {
      font-size: 15px;
    }
  }
}

.instructions {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 700;
    color: $dark90;

    @include media-breakpoint-down(545px) {
      font-size: 14px;
    }
  }

  p {
    margin: 6px 0;
    font-size: 14px;
    color: $dark70;
    font-weight: 500;

    @include media-breakpoint-down(545px) {
      font-size: 13px;
    }
  }
}

.exampleContainer {
  margin-top: 12px;
}

.example {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #d5d5dc;
  margin: 8px 0;
  font-family: inherit;
}

.exampleTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;

  th,
  td {
    border: 1px solid #d5d5dc;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f5f5f5;
    font-weight: 600;
    color: $dark90;
  }

  tr:nth-child(even) {
    background-color: #fafafa;
  }
}

.uploadSection {
  margin: 20px 0;
}

.fileInput {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: $dark70;
}

.fileName {
  margin: 10px 0;
  font-size: 14px;
  color: $dark70;
  font-weight: 500;
}

.previewContainer {
  margin: 20px 0;

  h3 {
    font-size: 15px;
    font-weight: 700;
    color: $dark90;
    margin-bottom: 10px;

    @include media-breakpoint-down(545px) {
      font-size: 14px;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
  max-width: 100%;
  border: 1px solid #d5d5dc;
  border-radius: 6px;
}

.previewTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;

  th,
  td {
    border: 1px solid #d5d5dc;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f5f5f5;
    font-weight: 600;
    color: $dark90;
  }

  tr:nth-child(even) {
    background-color: #fafafa;
  }
}

.errorMessage {
  color: red;
  padding: 12px;
  background-color: rgba(255, 0, 0, 0.05);
  border-radius: 6px;
  margin: 16px 0;
  font-size: 14px;
}

.resultsContainer {
  background-color: rgba(240, 84, 79, 0.05);
  padding: 15px;
  border-radius: 6px;
  margin: 20px 0;

  h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 700;
    color: $dark90;

    @include media-breakpoint-down(545px) {
      font-size: 14px;
    }
  }
}

.resultsSummary {
  display: flex;
  gap: 16px;
  margin-bottom: 15px;
  justify-content: space-between;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
    gap: 10px;
  }
}

.resultItem {
  padding: 10px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.resultLabel {
  font-size: 13px;
  color: $dark70;
  font-weight: 500;
}

.resultValue {
  font-size: 18px;
  font-weight: 700;
  color: $dark90;
}

.errorDetails {
  margin-top: 15px;

  h4 {
    font-size: 14px;
    font-weight: 700;
    color: $dark90;
    margin-bottom: 10px;
  }
}

.errorList {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 13px;
  color: $dark70;

  li {
    margin-bottom: 6px;
  }
}

.actionButtons {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  flex-direction: column;
}

.uploadButton,
.resetButton,
.doneButton {
  cursor: pointer;
  color: white;
  background-color: #f0544f;
  font-weight: 600;
  border: none;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 21px;
  width: 100%;

  &:hover:not(:disabled) {
    background-color: #c04440;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.resetButton {
  background-color: #f5f5f5;
  color: $dark90;

  &:hover:not(:disabled) {
    background-color: #e8e8e8;
    color: $dark90;
  }
}

.doneButton {
  margin-top: 30px;
}

.actionButtonsContainer {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
