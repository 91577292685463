@import 'src/styles/variables.scss';
@import 'src/styles/mixin.scss';

.wrapper {
  display: flex;
  gap: 30px;
  margin-top: 40px;

  @include media-breakpoint-down(545px) {
    flex-direction: column;
  }
}

.text {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.description {
  color: $dark70;
  font-weight: 500;
  font-size: 18px;

  @include media-breakpoint-down(545px) {
    font-size: 16px;
  }
}
